import { createAction } from 'redux-actions'
import { push } from 'react-router-redux'

export const setMembershipDetails = createAction('SET_MEMBERSHIP_DETAILS')
export const setCurrentFormStep = createAction('SET_CURRENT_FORM_STEP')
export const setSelectedMembershipType = createAction(
  'SET_SELECTED_MEMBERSHIP_TYPE'
)
export const setSelectedAddOns = createAction('SET_MEMBERSHIP_SELECTED_ADD_ONS')
export const removeSelectedAddOn = createAction(
  'REMOVE_MEMBERSHIP_SELECTED_ADD_ONS'
)
export const setSelectedMembershipDetails = createAction(
  'SET_SELECTED_MEMBERSHIP_DETAILS'
)
export const setSelectedSpecialSale = createAction('SET_SPECIAL_SALE')
export const setSellerCode = createAction('SET_SELLER_CODE')
export const setCoupon = createAction('SET_COUPON')
export const setAddOnDiscounts = createAction('SET_ADD_ON_DISCOUNTS')
export const setMembershipTypeDiscounts = createAction(
  'SET_MEMBERSHIP_TYPE_DISCOUNTS'
)
export const setOrder = createAction('SET_ORDER')
export const setRenewal = createAction('SET_RENEWAL')
export const clearSellerCodeAndCoupon = createAction(
  'CLEAR_SELLER_CODE_AND_COUPON'
)
export const clearMembershipForm = createAction('CLEAR_MEMBERSHIP_FORM')
export const setSelectedChildren = createAction('SET_SELECTED_CHILD')
export const setAddOnUpsellModalShown = createAction(
  'SET_ADD_ON_UPSELL_MODAL_SHOWN'
)
export const setMembershipDonation = createAction('SET_MEMBERSHIP_DONATION')
export const removeMembershipDonation = createAction(
  'REMOVE_MEMBERSHIP_DONATION'
)
export const setSelectedSecondaryMemberId = createAction(
  'SET_SELECTED_SECONDARY_MEMBER_ID'
)
export const setAddOnMember = createAction('SET_ADD_ON_MEMBER')
export const saveChildMemberInfo = createAction('SAVE_CHILD_MEMBER_INFO')
export const removeSelectedChildMember = createAction(
  'REMOVE_SELECTED_CHILD_MEMBER'
)
export const replaceSelectedChildMember = createAction(
  'REPLACE_SELECTED_CHILD_MEMBER'
)
export const setGiftCard = createAction('SET_GIFT_CARD')
export const clearGiftCard = createAction('CLEAR_GIFT_CARD')

export function goToMembershipDetails() {
  return push('/membership/details')
}

export function goToSpecialSale(slug) {
  return push(`/membership/${slug}`)
}

export function goToAddOns() {
  return push('/membership/add-ons')
}

export function goToPayment() {
  return push('/membership/pay')
}

export function goToConfirmation() {
  return push('/membership/confirmation')
}

export function goToPatronMembershipSelection() {
  return push('/membership/patron')
}
