import { handleActions } from 'redux-actions'
import { LOCATION_CHANGE } from 'react-router-redux'
import { setFromRequest } from '@launchpadlab/lp-redux-api'
import { selectorForSlice, setState } from '@launchpadlab/lp-redux-utils'
import { createSelector } from 'reselect'
import ReactGA from 'react-ga4'
import * as apiActions from 'main/apiActions'
import * as actions from './actions'
import {
  get,
  find,
  invert,
  isNil,
  mapValues,
  orderBy,
  pick,
  size,
  sum,
  subtract,
} from 'lodash'
import { set, unset } from 'lodash/fp'
import { arrayDifference, arrayUnion, joinNames, setIf } from 'utils'
import * as LS from 'services/sessionStorage'
import * as Types from 'main/types'

const reducerKey = 'membership'
const slice = 'root.membership'

const initialMembershipForm = {
  addOns: [],
  coupon: {},
  isRenewal: false,
  membershipDetails: {},
  membershipType: {},
  order: {},
  sellerCode: {},
  giftCard: {},
}

const initialMembershipDetails = {
  primaryMember: { isPrimary: true },
  secondaryMember: { isSecondary: true },
  children: [],
  numberOfChildren: null,
}

const initialState = {
  addOns: {},
  addOnUpsellModalMemberships: LS.getAddOnUpsellModalMemberships(),
  currentFormStep: LS.getMembershipFormStep() || 0,
  donationModalMemberships: LS.getDonationModalMemberships(),
  fnboDiscounts: {},
  membershipDetails: {},
  membershipTypes: {},
  selectedSpecialSale: null,
  membershipForm: LS.getMembershipForm() || initialMembershipForm,
  selectedSecondaryMemberId: null,
  totalFormSteps: 4,
}

const select = selectorForSlice(slice)

const reducer = handleActions(
  {
    ...setFromRequest(apiActions.REQ_ADD_ONS, 'addOns'),
    ...setFromRequest(apiActions.REQ_FNBO_DISCOUNTS, 'fnboDiscounts'),
    ...setFromRequest(apiActions.REQ_MEMBERSHIP_TYPES, 'membershipTypes'),
    ...setFromRequest(apiActions.REQ_SPECIAL_SALES, 'specialSales'),
    ...setFromRequest(apiActions.REQ_SPECIAL_SALE, 'specialSale'),
    ...setFromRequest(apiActions.REQ_MEMBER_DETAILS, 'membershipDetails'),
    ...setFromRequest(apiActions.REQ_MEMBER_MOBILE_WALLET, 'mobileWallet'),
    [LOCATION_CHANGE]: (state, { payload: { pathname } }) => {
      const formStep = invert(Types.MemberFormStepRoutes)[pathname]
      if (!formStep) return state

      return set('currentFormStep', Number(formStep), state)
    },
    [actions.clearMembershipForm]: () => {
      LS.clearMembershipForm()
      LS.clearMemberId()
      LS.clearAuthToken()
      LS.clearMembershipFormStep()
      return { ...initialState, membershipForm: initialMembershipForm }
    },
    [actions.clearSellerCodeAndCoupon]: (state) => {
      const formObj = { ...state.membershipForm, coupon: {}, sellerCode: {} }
      LS.setMembershipForm(formObj)
      return set('membershipForm', formObj, state)
    },
    // Match fetchMemberDetails api action
    [actions.setMembershipDetails]: setState(
      'membershipDetails.success.data.attributes'
    ),
    [actions.setCurrentFormStep]: (state, { payload }) => {
      LS.setMembershipFormStep(payload)
      return set('currentFormStep', payload, state)
    },
    [actions.setSelectedSpecialSale]: (state, { payload: specialSale }) => {
      const formObj = {
        ...state.membershipForm,
        selectedSpecialSale: specialSale,
      }
      LS.setMembershipForm(formObj)
      return set('membershipForm', formObj, state)
    },
    [actions.setSelectedAddOns]: (state, { payload: addOn }) => {
      const addOnObj = {
        ...pick(addOn, [
          'addOnType',
          'displayName',
          'cost',
          'fullPrice',
          'isGuest',
          'isGuestOne',
          'isNanny',
        ]),
        addOnId: addOn.addOnId,
        quantity: 1,
      }
      const formObj = {
        ...state.membershipForm,
        addOns: arrayUnion(state.membershipForm.addOns, [addOnObj]),
      }
      LS.setMembershipForm(formObj)
      ReactGA.event('add_to_cart', {
        currency: 'USD',
        value: addOn.cost,
        items: [
          {
            item_id: addOn.id,
            item_name: addOn.displayName,
            item_category: 'membership',
            item_category2: addOn.addOnType,
            price: addOn.cost,
            quantity: 1,
          },
        ],
      })
      return set('membershipForm', formObj, state)
    },
    [actions.removeSelectedAddOn]: (state, { payload: addOn }) => {
      const addOnsToRemove = addOn.isGuestOne
        ? state.membershipForm.addOns.filter((addOn) => addOn.isGuest)
        : [
            find(
              state.membershipForm.addOns,
              (a) => a.addOnId === addOn.addOnId
            ),
          ]
      const formObj = {
        ...state.membershipForm,
        addOns: arrayDifference(state.membershipForm.addOns, addOnsToRemove),
      }
      LS.setMembershipForm(formObj)
      ReactGA.event('remove_from_cart', {
        currency: 'USD',
        value: addOn.cost,
        items: [
          {
            item_id: addOn.id,
            item_name: addOn.displayName,
            item_category: 'membership',
            item_category2: addOn.addOnType,
            price: addOn.cost,
            quantity: 1,
          },
        ],
      })
      return set('membershipForm', formObj, state)
    },
    [actions.setAddOnMember]: (state, { payload }) => {
      const addOns = state.membershipForm.addOns.map((addOn) => {
        if (addOn.addOnId !== payload.addOnId) return addOn
        return {
          ...addOn,
          names: [
            {
              fullName: joinNames(payload.firstName, payload.lastName),
              firstName: payload.firstName || '',
              lastName: payload.lastName || '',
            },
          ],
          memberId: payload.memberId,
        }
      })
      const formObj = { ...state.membershipForm, addOns }
      LS.setMembershipForm(formObj)
      return set('membershipForm', formObj, state)
    },
    [actions.setCoupon]: (state, { payload: coupon }) => {
      const formObj = { ...state.membershipForm, coupon }
      LS.setMembershipForm(formObj)
      return set('membershipForm.coupon', coupon, state)
    },
    [actions.setRenewal]: (state, { payload }) => {
      const formObj = { ...state.membershipForm, isRenewal: payload }
      LS.setMembershipForm(formObj)
      return set('membershipForm', formObj, state)
    },
    [actions.setSelectedChildren]: setState(
      'membershipForm.membershipDetails.children',
      ({ payload }, state) => {
        const formObj = set(
          'membershipDetails.children',
          payload,
          state.membershipForm
        )
        LS.setMembershipForm(formObj)
        return payload
      }
    ),
    [actions.saveChildMemberInfo]: setState(
      'membershipForm.membershipDetails.children',
      ({ payload }, state) => {
        const existingChildren = get(
          state,
          'membershipForm.membershipDetails.children',
          []
        )
        const existingChild = existingChildren.find(
          (child) => child.id === payload.id
        )

        const updatedChildren = existingChild
          ? existingChildren.map((child) => {
              if (child.id !== payload.id) return child
              return payload
            })
          : [...existingChildren, payload]

        const formObj = set(
          'membershipDetails.children',
          updatedChildren,
          state.membershipForm
        )
        LS.setMembershipForm(formObj)
        return updatedChildren
      }
    ),
    [actions.removeSelectedChildMember]: setState(
      'membershipForm.membershipDetails.children',
      ({ payload }, state) => {
        const existingChildren = get(
          state,
          'membershipForm.membershipDetails.children',
          []
        )

        // Remove the child from the membership form "cart" based on its index in the array (mirroring the redux-form FieldArray fields.remove(i) functionality)
        const remainingChildren = existingChildren.filter(
          (child, index) => index !== payload
        )

        LS.setMembershipForm(
          set(
            'membershipDetails.children',
            remainingChildren,
            state.membershipForm
          )
        )
        return remainingChildren
      }
    ),
    [actions.replaceSelectedChildMember]: setState(
      'membershipForm.membershipDetails.children',
      ({ payload }, state) => {
        const existingChildren = get(
          state,
          'membershipForm.membershipDetails.children',
          []
        )
        const updatedChildren = existingChildren.map((child) => {
          if (child.id !== payload.id) return child
          return payload.member
        })

        LS.setMembershipForm(
          set(
            'membershipDetails.children',
            updatedChildren,
            state.membershipForm
          )
        )
        return updatedChildren
      }
    ),
    [actions.setSelectedMembershipType]: (
      state,
      { payload: membershipType }
    ) => {
      const currentTypeId = get(state, 'membershipForm.membershipType.id')
      const typeChanged = currentTypeId && currentTypeId !== membershipType.id
      const primaryMember = state.membershipForm.membershipDetails.primaryMember
      const formObj = typeChanged
        ? {
            ...initialMembershipForm,
            membershipDetails: { primaryMember },
            membershipType,
          }
        : { ...state.membershipForm, membershipType }
      LS.setMembershipForm(formObj)
      return set('membershipForm', formObj, state)
    },
    [actions.setSelectedMembershipDetails]: (
      state,
      { payload: membershipDetails }
    ) => {
      const currentDetails = state.membershipForm.membershipDetails
      const mappedNewDetails = mapFullNames(membershipDetails)
      const updatedDetails = { ...currentDetails, ...mappedNewDetails }
      const formObj = {
        ...state.membershipForm,
        membershipDetails: updatedDetails,
      }
      LS.setMembershipForm(formObj)
      return set('membershipForm', formObj, state)
    },
    [actions.setSellerCode]: (state, { payload: sellerCode }) => {
      const formObj = { ...state.membershipForm, sellerCode }
      LS.setMembershipForm(formObj)
      return set('membershipForm.sellerCode', sellerCode, state)
    },
    [actions.setAddOnDiscounts]: (state, { payload: { addOns } }) => {
      // Id returned from db for addOn is 'id' but the id
      // in memership form addOns is 'addOnId'
      const updatedAddOns = state.membershipForm.addOns.map((addOn) => ({
        ...addOn,
        cost: addOns.find((ao) => ao.id === addOn.addOnId).cost,
        fullPrice: addOns.find((ao) => ao.id === addOn.addOnId).fullPrice,
      }))
      const formObj = { ...state.membershipForm, addOns: updatedAddOns }
      LS.setMembershipForm(formObj)
      return set('membershipForm.addOns', updatedAddOns, state)
    },
    [actions.setMembershipTypeDiscounts]: (
      state,
      { payload: { membershipType } }
    ) => {
      const updatedMembershipType = {
        ...state.membershipForm.membershipType,
        cost: membershipType.cost,
        fullPrice: membershipType.fullPrice,
      }
      const formObj = {
        ...state.membershipForm,
        membershipType: updatedMembershipType,
      }
      LS.setMembershipForm(formObj)
      return set('membershipForm.membershipType', updatedMembershipType, state)
    },
    [actions.setOrder]: (state, { payload: { order } }) => {
      const formObj = { ...state.membershipForm, order }
      LS.setMembershipForm(formObj)
      return set('membershipForm', formObj, state)
    },
    [actions.setAddOnUpsellModalShown]: setState(
      'addOnUpsellModalMemberships',
      ({ payload: id }, state) => {
        const addOnMemberships = {
          ...state.addOnUpsellModalMemberships,
          [id]: true,
        }

        LS.setAddOnUpsellModalMemberships(addOnMemberships)
        return addOnMemberships
      }
    ),
    [actions.setMembershipDonation]: setState(
      'membershipForm.donation',
      ({ payload: donation }, state) => {
        LS.setMembershipForm(set('donation', donation, state.membershipForm))
        return donation
      }
    ),
    [actions.removeMembershipDonation]: (state) => {
      const updatedMembershipForm = unset('donation', state.membershipForm)
      LS.setMembershipForm(updatedMembershipForm)
      return set('membershipForm', updatedMembershipForm, state)
    },
    [actions.setSelectedSecondaryMemberId]: setState(
      'membershipForm.membershipDetails.secondaryMember.id'
    ),
    [actions.setGiftCard]: (state, { payload: total }) => {
      const formObj = {
        ...state.membershipForm,
        giftCard: {
          giftCardNumber: get(total, 'giftCardNumber'),
          totalGiftCard: get(total, 'totalGiftCard'),
          giftCardBalance: get(total, 'giftCardBalance'),
        },
      }
      LS.setMembershipForm(formObj)
      return set('membershipForm', formObj, state)
    },
    [actions.clearGiftCard]: (state) => {
      const formObj = { ...state.membershipForm, giftCard: {} }
      LS.setMembershipForm(formObj)
      return set('membershipForm', formObj, state)
    },
  },
  initialState
)

const selectors = {
  addOns: select('addOns.success.data.attributes', []),
  addOnUpsellModalMemberships: select('addOnUpsellModalMemberships'),
  currentFormStep: select('currentFormStep'),
  donationModalMemberships: select('donationModalMemberships'),
  donationAmount: select('membershipForm.donation.amount'),
  coupon: select('membershipForm.coupon'),
  fnboDiscounts: select('fnboDiscounts.success.data.attributes'),
  isRenewal: select('membershipForm.isRenewal'),
  membershipDetails: select('membershipDetails.success.data.attributes'),
  membershipForm: select('membershipForm'),
  membershipTypes: select('membershipTypes.success.data.attributes'),
  specialSales: select('specialSales.success.data.attributes'),
  specialSale: select('specialSale.success.data.attributes'),
  order: select('membershipForm.order'),
  selectedAddOns: select('membershipForm.addOns'),
  selectedMembershipType: select('membershipForm.membershipType'),
  selectedMembershipDetails: select('membershipForm.membershipDetails'),
  selectedChildren: select('membershipForm.membershipDetails.children', []),
  selectedSecondaryMemberId: select(
    'membershipForm.membershipDetails.secondaryMember.id'
  ),
  sellerCode: select('membershipForm.sellerCode'),
  totalFormSteps: select('totalFormSteps'),
  selectedSecondaryMember: select(
    'membershipForm.membershipDetails.secondaryMember'
  ),
  appliedGiftCard: select('membershipForm.giftCard'),
  mobileWallet: select('mobileWallet.success'),
}

selectors.appliedGiftCardAmount = createSelector(
  [selectors.appliedGiftCard],
  function (giftCard) {
    return get(giftCard, 'totalGiftCard')
  }
)

selectors.orderTotals = createSelector(
  [
    selectors.selectedMembershipType,
    selectors.selectedAddOns,
    selectors.donationAmount,
    selectors.appliedGiftCardAmount,
  ],
  function (type, addOns, donationAmount, giftCardAmount) {
    const addOnTotalCost = sum(addOns.map((addOn) => addOn.cost))
    const totalRevenue = sum([type.cost, addOnTotalCost, donationAmount])

    const addOnTotalFullPrice = sum(addOns.map((addOn) => addOn.fullPrice))
    const fullPriceTotal = sum([
      type.fullPrice,
      addOnTotalFullPrice,
      donationAmount,
    ])

    return {
      totalRevenue,
      total: subtract(totalRevenue, giftCardAmount),
      discount: subtract(fullPriceTotal, totalRevenue),
      fullPriceTotal,
    }
  }
)

selectors.remainingGiftCardBalance = createSelector(
  [selectors.appliedGiftCard],
  function (giftCard) {
    return get(giftCard, 'giftCardBalance')
  }
)

selectors.addOnsToDisplay = createSelector(
  [selectors.addOns, selectors.selectedAddOns],
  function (addOns, selectedAddOns) {
    const includesGuestOne = selectedAddOns.filter(
      (addOn) => addOn.isGuestOne
    ).length
    return includesGuestOne
      ? addOns
      : addOns.filter((addOn) => !addOn.isGuestTwo)
  }
)

selectors.membershipDetailsInitialValues = createSelector(
  [selectors.membershipDetails, selectors.selectedMembershipDetails],
  function (details, formDetails) {
    const hasFormDetails =
      formDetails.primaryMember || formDetails.secondaryMember
    const primaryMember =
      get(formDetails, 'primaryMember') || find(details, 'isPrimary')
    const secondaryMember = {
      ...get(formDetails, 'secondaryMember', {}),
      isSecondary: true,
    }
    const children = get(formDetails, 'children', [])

    return hasFormDetails || details
      ? {
          primaryMember,
          secondaryMember,
          children,
          numberOfChildren: size(children) || null,
        }
      : initialMembershipDetails
  }
)

selectors.orderedMembershipTypes = createSelector(
  [selectors.membershipTypes],
  function (membershipTypes) {
    return membershipTypes ? orderBy(membershipTypes, 'position') : null
  }
)

selectors.orderedMainMembershipTypes = createSelector(
  [selectors.orderedMembershipTypes],
  function (membershipTypes) {
    if (!membershipTypes) return null
    return membershipTypes.filter(
      (type) => !type.patron && !type.patronDeclinedBenefits
    )
  }
)

selectors.orderedPatronMembershipTypes = createSelector(
  [selectors.orderedMembershipTypes],
  function (membershipTypes) {
    if (!membershipTypes) return null
    return membershipTypes.filter((type) => type.patron)
  }
)

selectors.orderedPatronDeclinedBenefitsMembershipTypes = createSelector(
  [selectors.orderedMembershipTypes],
  function (membershipTypes) {
    if (!membershipTypes) return null
    return membershipTypes.filter((type) => type.patronDeclinedBenefits)
  }
)

selectors.nonPrimaryMembers = createSelector(
  [selectors.membershipDetails],
  function (details) {
    return details
      ? details.filter((member) => !member.isPrimary && member.hasName)
      : []
  }
)

selectors.selectedAddOnMembers = createSelector(
  [selectors.membershipForm],
  function (form) {
    if (!form) return []
    return form.addOns.filter((addOn) => !!addOn.memberId)
  }
)

selectors.secondaryMemberOptions = createSelector(
  [
    selectors.nonPrimaryMembers,
    selectors.selectedChildren,
    selectors.selectedAddOnMembers,
  ],
  function (memberOptions, selectedChildren, selectedAddOnMembers) {
    const childMembers = selectedChildren.map((child) => child.id)
    const addOnMembers = selectedAddOnMembers.map((addOn) => addOn.memberId)
    const idsToFilter = [...childMembers, ...addOnMembers]

    return memberOptions
      .filter((member) => !idsToFilter.includes(member.id))
      .map((member) => ({
        key: joinNames(member.firstName, member.lastName),
        value: member.id,
      }))
  }
)

selectors.otherMemberOptions = createSelector(
  [
    selectors.nonPrimaryMembers,
    selectors.selectedSecondaryMemberId,
    selectors.selectedChildren,
    selectors.selectedAddOnMembers,
  ],
  function (
    memberOptions,
    selectedSecondaryMemberId,
    selectedChildren,
    selectedAddOnMembers
  ) {
    const selectedChildrenIds = selectedChildren.map((child) => child.id)
    const selectedAddOnIds = selectedAddOnMembers.map((addOn) => addOn.memberId)
    const idsToFilter = [...selectedChildrenIds, ...selectedAddOnIds]

    return memberOptions
      .filter(
        (member) =>
          member.id !== selectedSecondaryMemberId &&
          !idsToFilter.includes(member.id)
      )
      .map((member) => ({
        key: joinNames(member.firstName, member.lastName),
        value: member.id,
        firstName: member.firstName,
        lastName: member.lastName,
      }))
  }
)

selectors.primaryMember = createSelector(
  [selectors.membershipDetails],
  function (membershipDetails) {
    if (!membershipDetails) return null
    return membershipDetails.find((member) => member.isPrimary)
  }
)

selectors.primaryMembershipId = createSelector(
  [selectors.primaryMember],
  function (primaryMember) {
    if (!primaryMember) return null
    return get(primaryMember, 'currentMembershipId')
  }
)

selectors.currentMembership = createSelector(
  [selectors.membershipTypes, selectors.primaryMembershipId],
  function (membershipTypes, id) {
    if (!membershipTypes || !id) return null
    return membershipTypes.find((type) => type.id === id)
  }
)

selectors.autoAddOns = createSelector([selectors.addOns], function (addOns) {
  return addOns.filter((addOn) => addOn.autoAdd)
})

selectors.addOnUpsellModalShown = createSelector(
  [selectors.selectedMembershipType, selectors.addOnUpsellModalMemberships],
  function (membership, addOnUpsellModalMemberships) {
    return !!addOnUpsellModalMemberships[membership.id]
  }
)

selectors.hasNannyAddOn = createSelector(
  [selectors.selectedAddOns],
  function (selectedAddOns) {
    if (!selectedAddOns) return false

    return !!selectedAddOns.find((addOn) => addOn.isNanny)
  }
)

selectors.formName = createSelector(
  [selectors.selectedMembershipType],
  function (selectedMembershipType) {
    const membershipTypeName = selectedMembershipType.membershipType
    return membershipTypeName ? `membership-${membershipTypeName}` : ''
  }
)

selectors.childMembers = createSelector(
  [selectors.membershipDetails],
  function (membershipDetails) {
    if (!membershipDetails) return null
    return membershipDetails.filter((member) => member.isChild)
  }
)

selectors.secondaryMember = createSelector(
  [selectors.membershipDetails],
  function (membershipDetails) {
    if (!membershipDetails) return null
    return membershipDetails.find((member) => member.isSecondary) || {}
  }
)

selectors.nannyMember = createSelector(
  [selectors.membershipDetails],
  function (membershipDetails) {
    if (!membershipDetails) return null
    return membershipDetails.find((member) => member.isNanny) || {}
  }
)

selectors.guestMembers = createSelector(
  [selectors.membershipDetails],
  function (membershipDetails) {
    if (!membershipDetails) return null
    return membershipDetails.filter((member) => !member.hasActiveMembership)
  }
)

// ----- PRIVATE -----

// Maps over form sections and adds/updates a full name attribute for easier reference
function mapFullNames(params) {
  return mapValues(params, (section) => {
    // If the section doesn't exist or is just a field value, then do not map names
    if (isNil(section) || typeof section !== 'object') return section

    if (!Array.isArray(section)) return setFullName(section)

    return section.map(setFullName)
  })
}

// Only set a full name if there is a name key present, otherwise returns the original object
function setFullName(obj) {
  return setIf(
    obj.firstName || obj.lastName,
    'fullName',
    joinNames(obj.firstName, obj.lastName)
  )(obj)
}

export { reducer, selectors, reducerKey }
