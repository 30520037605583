import React from 'react'
import { Route, IndexRoute } from 'react-router'
import * as Views from './views'

const Routes = (
  <Route path="membership">
    <IndexRoute component={Views.SelectMembership} />
    <Route path="patron" component={Views.SelectPatronLevel} />
    <Route path="details" component={Views.MembershipDetails} />
    <Route path="add-ons" component={Views.SelectAddOns} />
    <Route path="pay" component={Views.PaymentDetails} />
    <Route path="confirmation" component={Views.Confirmation} />
    <Route path=":specialSalesId" component={Views.SelectSpecialSalesMembership} />
  </Route>
)

export default Routes
