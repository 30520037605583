import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'main/apiActions'
import SocietyCheckoutLayout from 'layout/SocietyCheckoutLayout'
import { MEMBERSHIP_CHECKOUT_STEPS } from '../../types'
import SocietyProductGrid from 'layout/SocietyProductGrid'

function SelectSpecialSalesMembership({
  location,
  specialSale,
  products,
  fetchMembershipTypes,
  fetchSpecialSale,
  setSelectedMembershipType,
  setSelectedSpecialSale,
}) {

  const slug = location.pathname.split('/').pop();
  useEffect(() => {
    fetchMembershipTypes();
    fetchSpecialSale(slug);
  }, [slug])

  if (!specialSale) {
    specialSale = {

    };
  }
  return (
    <SocietyCheckoutLayout
      progress={{ steps: MEMBERSHIP_CHECKOUT_STEPS, currentStep: 0 }}
      title={specialSale?.title}
      backUrl="/membership"
    >
      <div className="mx-auto mb-10 bg-white shadow lg:w-2/3">
        <div
          className="bg-contain bg-no-repeat bg-center h-64"
          style={{ backgroundImage: `url(${specialSale?.image?.url})` }}
        />
        <h2 className="mx-5 mt-3 mb-0">
          {specialSale?.title?.toUpperCase()}
        </h2>
        <ul className="pb-5 px-5 pt-5 mx-5 space-y-4 list-disc">
          <p key='description' className="mb-0">
            {specialSale?.description}
          </p>
        </ul>
      </div>

      <div className="mx-auto lg:w-2/3">
        <SocietyProductGrid
          products={products}
          singleColumn
          onSelect={({ membership }) => {
            setSelectedMembershipType(membership);
            setSelectedSpecialSale(specialSale);
          }}
        />
      </div>
    </SocietyCheckoutLayout>
  )
}

function mapStateToProps(state) {
  const membershipTypes = selectors.orderedMainMembershipTypes(state)
  const specialSale = selectors.specialSale(state);

  if (!membershipTypes)
    return {
      products: [],
      specialSale: null,
    }

  const currentMembership = selectors.currentMembership(state)
  let membershipTypesFiltered = membershipTypes.filter(membership =>
    specialSale?.membershipTypesAddOns.some(membershipTypeAddOn =>
      membership.id === membershipTypeAddOn.membershipTypeId
    )
  );

  // 1 product for each membership type, 1 product for patrons, 1 product for special sales
  const products = [
    ...membershipTypesFiltered.map((membership) => {
      return {
        price: `$${membership.cost} per year`,
        priceNote: membership.priceNote,
        featured: currentMembership?.id === membership.id,
        featuredLabel: 'Current Membership',
        imageUrl: membership.image,
        description: membership.descriptionText,
        productUrl: membership.learnMoreUrl,
        selectUrl: `/membership/details?activityId=${membership.id}&backPath=activity`,
        signInUrl: `/sign-in?guestFlowDisabled=1&redirectUrl=${encodeURIComponent(
          '/membership/' + specialSale.slug
        )}`,
        name: membership.displayName,
        fullDescription: membership.infoModalText,
        isLoginRequired: true,
        membership,
      }
    }),
  ]

  return {
    products,
    specialSale,
  }
}

const mapDispatchToProps = {
  fetchMembershipTypes: apiActions.fetchMembershipTypes,
  fetchSpecialSale: apiActions.fetchSpecialSale,
  setSelectedMembershipType: actions.setSelectedMembershipType,
  setSelectedSpecialSale: actions.setSelectedSpecialSale,
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectSpecialSalesMembership)
