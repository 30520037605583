import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { onMount, waitFor } from '@launchpadlab/lp-hoc'
import { Spinner } from '@launchpadlab/lp-components'
import * as apiActions from 'main/apiActions'
import * as actions from '../actions'
import { selectors } from '../reducer'
import * as Types from 'main/types'
import * as routerActions from 'react-router-redux'
import { submit } from 'redux-form'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import { MEMBERSHIP_CHECKOUT_STEPS } from '../../types'
import SocietyProductCard from '../../../layout/SocietyProductCard'
import { debounce, get, isEmpty, trim, uniqBy } from 'lodash'
import { NEW_MEMBER_ID } from 'config'
import { AddOnNannyForm } from '../forms'
import { displaySubmitFailure, findFirstErrorField, setIf } from 'utils'
import { scroller } from 'react-scroll'
import MembershipUpgradeModal from '../../../components/MembershipUpgradeModal'
import { OrderReceipt } from '../components'

const propTypes = {
  addOns: PropTypes.arrayOf(Types.addOn).isRequired,
  hasNannyAddOn: PropTypes.bool.isRequired,
  nonPrimaryMembers: PropTypes.arrayOf(Types.member),
  otherMemberOptions: PropTypes.array,
  push: PropTypes.func.isRequired,
  setAddOns: PropTypes.func.isRequired,
  removeAddOn: PropTypes.func.isRequired,
  selectedMembershipType: Types.membershipType.isRequired,
  selectedAddOns: PropTypes.arrayOf(Types.addOn),
  setAddOnMember: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  autoAddOns: PropTypes.arrayOf(Types.addOn),
  specialSale: PropTypes.object,
}

const defaultProps = {
  nonPrimaryMembers: [],
  otherMemberOptions: [],
  selectedAddOns: [],
}

function getMemberOptions({ selectedMember, memberOptions }) {
  if (!selectedMember || !selectedMember.memberId) return memberOptions
  const selectedOption = {
    key: get(selectedMember, 'names[0].fullName'),
    value: selectedMember.memberId,
  }
  return uniqBy([selectedOption, ...memberOptions], 'value')
}

function SelectAddOns({
  addOns,
  specialSale,
  hasNannyAddOn,
  nonPrimaryMembers,
  otherMemberOptions,
  push,
  removeAddOn,
  selectedMembershipType,
  selectedAddOns,
  setAddOnMember,
  setAddOns,
  submitForm,
  autoAddOns,
}) {
  useEffect(() => {
    const wereAutoAddOnsAdded = localStorage.getItem('auto-add-ons-added')
    if (autoAddOns && !wereAutoAddOnsAdded) {
      autoAddOns.forEach(setAddOns)
      localStorage.setItem('auto-add-ons-added', true)
    }

    if (specialSale) {
      const addOn = addOns.find(addOn => specialSale.membershipTypesAddOns.some(membershipTypeAddOn => membershipTypeAddOn.membershipTypeId === selectedMembershipType.id && membershipTypeAddOn.addOnId === addOn.id))
      if (addOn) {
        setAddOns(addOn)
      }
    }
  }, [autoAddOns])

  return (
    <SocietyCheckoutLayout
      title="Select Add-Ons"
      backUrl="/membership/details"
      progress={{ steps: MEMBERSHIP_CHECKOUT_STEPS, currentStep: 2 }}
      summary={<OrderReceipt />}
      onContinue={() => {
        if (!hasNannyAddOn) return push('/membership/pay')
        return submitForm('membership-nanny')
      }}
      idme={false}
    >
      <p>
        You have chosen a{' '}
        <strong>{selectedMembershipType.displayName} Membership</strong>. Below
        are optional add-ons you can include with your membership.
      </p>
      <ol className="space-y-5">

        {addOns.map((addOn, key) => {
          // check if it's in special sale
          let disabled = false;
          let selectLabel = null;
          if (specialSale) {
            if
              (specialSale.membershipTypesAddOns.some(membershipTypeAddOn => membershipTypeAddOn.membershipTypeId === selectedMembershipType.id && membershipTypeAddOn.addOnId === addOn.id)) {
              disabled = true;
              selectLabel = "INCLUDED";
            }
          }
          const selectedAddOn = selectedAddOns.find(
            (ao) => ao.addOnId === addOn.addOnId
          )

          const nannyMemberOptions = getMemberOptions({
            selectedMember: selectedAddOn,
            memberOptions: otherMemberOptions,
          })

          const handleNannyFormChange = debounce(({ nanny = {} } = {}) => {
            // note: have to make own invalid check due to redux form
            // bug where validations are performed on previous form values
            const { firstName, lastName, id } = nanny
            const manualInvalid = !trim(firstName) || !trim(lastName)
            // Verify that the name is valid (if typed in)
            if (id === NEW_MEMBER_ID && manualInvalid) return

            return setAddOnMember({
              addOnId: addOn.addOnId,
              memberId: id,
              firstName,
              lastName,
            })
          }, 500)

          const handleNannyFormSubmitFail = (
            errors,
            dispatch,
            submitError,
            { syncErrors }
          ) => {
            displaySubmitFailure(errors, dispatch, submitError)
            if (!isEmpty(syncErrors)) {
              const firstErrorField = findFirstErrorField(syncErrors)
              if (!firstErrorField) return

              // If the error is form wide, scroll to the select input
              if (firstErrorField === 'nanny')
                return scroller.scrollTo('nanny.id', { smooth: true })

              return scroller.scrollTo(firstErrorField, {
                smooth: true,
              })
            }
          }

          const { firstName, lastName } = get(selectedAddOn, 'names[0]', {})
          const memberId = get(selectedAddOn, 'memberId')
          const initialValues = compose(
            setIf(memberId, 'nanny.id', memberId),
            setIf(firstName, 'nanny.firstName', firstName),
            setIf(lastName, 'nanny.lastName', lastName)
          )({})

          return (
            <li key={key} className="mb-0">
              <SocietyProductCard
                disabled={disabled}
                id={addOn.id}
                featured={addOn.featured}
                featuredLabel={addOn.featuredDisplayLabel}
                name={addOn.displayName}
                description={addOn.displayDescription}
                imageUrl={addOn.image}
                productUrl={addOn.learnMoreUrl}
                fullDescription={addOn.infoModalText}
                price={addOn.cost}
                horizontal
                onSelect={() =>
                  selectedAddOn ? removeAddOn(addOn) : setAddOns(addOn)
                }
                startsExpanded={addOn.isNanny}
                triggersNextStep={false}
                selected={!!selectedAddOn}
                selectLabel={selectLabel}
              >
                <AddOnNannyForm
                  memberOptions={nannyMemberOptions}
                  members={nonPrimaryMembers}
                  onChange={handleNannyFormChange}
                  onSubmitSuccess={() => push('/membership/pay')}
                  onSubmitFail={handleNannyFormSubmitFail}
                  initialValues={initialValues}
                />
              </SocietyProductCard>
            </li>
          )
        })}
      </ol>
      {selectedMembershipType.autoAdd && <MembershipUpgradeModal />}
    </SocietyCheckoutLayout>
  )
}

SelectAddOns.propTypes = propTypes
SelectAddOns.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    addOns: selectors.addOnsToDisplay(state),
    addOnUpsellModalShown: selectors.addOnUpsellModalShown(state),
    autoAddOns: selectors.autoAddOns(state),
    hasNannyAddOn: selectors.hasNannyAddOn(state),
    nonPrimaryMembers: selectors.nonPrimaryMembers(state),
    otherMemberOptions: selectors.otherMemberOptions(state),
    selectedMembershipType: selectors.selectedMembershipType(state),
    selectedAddOns: selectors.selectedAddOns(state),
    specialSale: selectors.specialSale(state),
  }
}

const mapDispatchToProps = {
  fetchAddOns: apiActions.fetchAddOns,
  push: routerActions.push,
  setAddOns: actions.setSelectedAddOns,
  setAddOnMember: actions.setAddOnMember,
  removeAddOn: actions.removeSelectedAddOn,
  setAddOnUpsellModalShown: actions.setAddOnUpsellModalShown,
  setSelectedAddOns: actions.setSelectedAddOns,
  submitForm: submit,
}

function fetchAddOnsWithMembership({ fetchAddOns, selectedMembershipType }) {
  return fetchAddOns(selectedMembershipType.id)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onMount(fetchAddOnsWithMembership),
  waitFor('addOns', Spinner)
)(SelectAddOns)
